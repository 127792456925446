import { PedResponseCode } from "../types";

export default {
  "#003": {
    id: "MSG99614",
    description: "Ask the customer to insert their card or device.",
  },
  "#005": {
    id: "MSG99614",
    description: "The customer is being asked to enter their PIN. Ask them to follow the instructions on the PIN Pad.",
  },
  "#008": {
    id: "MSG99613",
    description: "Prompt the customer to remove their card.",
  },
  "#002": {
    id: "MSG00959",
    description:
      "The card cannot be processed by the PINPad. Swipe the card or press Cancel if the customer decides not to proceed.",
  },
  "SIGNATURE OK": {
    id: "MSG00839",
    description: "Check the signature on the card against the receipt.\n\nSignature OK?",
  },
  "CARD ERROR SWIPE CARD": {
    id: "MSG00959",
    description:
      "The card cannot be processed by the PINPad.\n\nSwipe the card or press Cancel if the customer decides not to proceed.",
  },
  "#006": {
    id: "MSG40079",
    description: "PIN Entry in progress (retry)",
  },
  "#007": {
    id: "MSG40079",
    description: "PIN Entry in progress (last try)",
  },
} as PedResponseCode;
