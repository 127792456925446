import { SupportedServices, Response, PosDisplayEvent } from "../../types";
import WebSocketAsPromised from "websocket-as-promised";
import {
  IngenicoPedClient,
  PedActions,
  PedInitialisationResult,
  PedResult,
  IngenicoPedClientProps,
  TransactionTypes,
} from "./types";
import { performAction as performSimulatorAction } from "./mock/index";
import pedResponseCodes from "./mappings/pedResponseCodes";
import {
  extractMessaging,
  extractMetadata,
  isApproved,
  isError,
  PaymentActions,
  setFallbackMessaging,
  SUCCESSFUL_TRANSACTION_PROMPT,
} from "./helpers";

export const buildClient = (
  props: IngenicoPedClientProps,
  requestHandler: WebSocketAsPromised["sendRequest"]
): IngenicoPedClient => {
  const { terminalId, clerkId, useMock } = props;

  const performAction = async (action: PedActions, params: Record<string, unknown>): Promise<PedResult> => {
    if (useMock) {
      return Promise.resolve(await performSimulatorAction(action));
    }

    if (params.amount) {
      params.amount = Math.abs(params.amount as number);
    }

    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action,
      params: {
        terminalId,
        clerkId,
        ...params,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    const transactionType = PaymentActions.includes(action) ? TransactionTypes.Payments : TransactionTypes.Banking;
    const pedResponse = response.result as PedResult;

    pedResponse.metadata = extractMetadata(action, pedResponse);

    if (!isApproved(pedResponse)) {
      const prompt = extractMessaging(transactionType, pedResponse);

      if (prompt) {
        pedResponse.prompt = prompt;
      }

      // attempt to fallback to c3 response code errors
      if (!prompt && pedResponseCodes[pedResponse.combinedCode]) {
        pedResponse.prompt = pedResponseCodes[pedResponse.combinedCode];
      }

      // assign fallback messaging
      if (!pedResponse.prompt) {
        pedResponse.prompt = setFallbackMessaging(transactionType);
      }

      return Promise.reject(pedResponse);
    }

    // success prompt
    pedResponse.prompt = SUCCESSFUL_TRANSACTION_PROMPT;

    return Promise.resolve(pedResponse);
  };

  const debitCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DebitX, { amount, transactionId });
  };

  const debit = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DebitY, {
      transactionId,
      amount,
    });
  };

  const withdrawalCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.WithdrawalX, {
      transactionId,
      amount,
    });
  };

  const withdrawal = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.WithdrawalY, {
      transactionId,
      amount,
    });
  };

  const depositCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DepositX, {
      transactionId,
      amount,
    });
  };

  const deposit = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DepositY, {
      transactionId,
      amount,
    });
  };

  const balanceEnquiryCheck = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.BalanceEnquiryX, {
      transactionId,
    });
  };

  const balanceEnquiry = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.BalanceEnquiryY, {
      transactionId,
    });
  };

  const changePinCheck = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.ChangePinX, {
      transactionId,
    });
  };

  const changePin = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.ChangePinY, {
      transactionId,
    });
  };

  const initialise = async (): Promise<boolean> => {
    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action: PedActions.Initialise,
      params: {
        terminalId,
        clerkId,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    const pedResponse = response.result as PedInitialisationResult;

    if (!pedResponse.initialised) {
      throw new Error("Failed to initialise");
    }

    return pedResponse.initialised;
  };

  const sendEvent = async (event: PosDisplayEvent["event"]): Promise<void> => {
    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action: PedActions.POSEvent,
      params: {
        response: event,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }
  };

  const abort = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.AbortX, {
      transactionId,
    });
  };

  return Object.freeze({
    debitCheck,
    debit,
    isApproved,
    initialise,
    withdrawalCheck,
    withdrawal,
    depositCheck,
    deposit,
    balanceEnquiryCheck,
    balanceEnquiry,
    changePinCheck,
    changePin,
    sendEvent,
    abort,
  });
};
