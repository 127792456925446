import { Blob } from "buffer";
import { Response } from "./types";

export const parseJson = (json: string | ArrayBuffer | Blob): Response | null => {
  try {
    if (typeof json === "string") {
      return JSON.parse(json);
    }
    return null;
  } catch (ex) {
    return null;
  }
};

export const isRunningInSimulator = window && window.location && window.location.hostname.split(".")[0] === "simulator";
