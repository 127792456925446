import { PedResponseCode } from "../types";

export default {
  "0900/0000": {
    id: "TBC",
    description: "Missing C3Config",
    resultCode: "07",
    responseCode: "40",
  },
  "0199/0000": {
    id: "TBC",
    description: "Incorrect C3Config parameter",
    resultCode: "07",
    responseCode: "40",
  },
  "0128/": {
    id: "TBC",
    description: "Invalid or unknown command",
    resultCode: "07",
    responseCode: "40",
  },
  "0100/": {
    id: "TBC",
    description: "Terminal timeout",
    resultCode: "07",
    responseCode: "40",
  },
  "0101/": {
    id: "MSG02223",
    description: "Pin pad not present.",
    resultCode: "07",
    responseCode: "40",
  },
  "0201/": {
    id: "MSG99603",
    description: "The transaction has failed.\n\nError communicating with AXIS.",
    resultCode: "07",
    responseCode: "38",
  },
  "0311/0070": {
    id: "MSG99603",
    description: "The transaction has failed.\n\nFailed connection to the authorisation host.",
    resultCode: "07",
    responseCode: "32",
  },
  "0000/6001": {
    id: "MSG99603",
    description:
      "The transaction has failed.\n\nThe verification on server was impossible, token offline generated by C3 is sent without verification on server to the POS.",
    resultCode: "07",
    responseCode: "38",
  },
  "0000/6002": {
    id: "MSG99603",
    description:
      "The transaction has failed.\n\nThe verification on server was possible, but inconsistency detected, despite this, the generated offline token (by GTS) is sent to the POS.",
  },
  "0310/0000": {
    id: "MSG99622",
    description: "The Transaction has been cancelled.",
  },
  "0310/0017": {
    id: "MSG99619",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
    resultCode: "02",
    responseCode: "01",
  },
  "0310/0085": {
    id: "MSG99604",
    description:
      "The transaction has been cancelled.\n\nAbort by the cardholder when asked to accept the processing fees.",
    resultCode: "04",
    responseCode: "01",
  },
  "0311/0014": {
    id: "MSG00999",
    description: "The transaction has been cancelled by the customer removing the card too early.",
    resultCode: "06",
    responseCode: "07",
  },
  "0311/0033": {
    id: "MSG00628",
    description: "Expired card",
    resultCode: "06",
    responseCode: "08",
  },
  "0311/0018": {
    id: "FRG92168",
    description:
      "PIN Pad not initialised.\n\nThis would typically be because there was no PIN Pad present at logon, or because initialisation failed.",
    resultCode: "07",
    responseCode: "40",
  },
  "0311/0021": {
    id: "MSG99603",
    description: "The transaction has failed.\n\nFull offline transactions.",
    resultCode: "07",
    responseCode: "40",
  },
  "0311/0004": {
    id: "MSG99619",
    description: "The transaction has been declined.",
    resultCode: "06",
    responseCode: "08",
  },
  "0311/2006": {
    id: "TBC",
    description: "The transaction has failed.\n\n.Amount less than the minimum",
    resultCode: "06",
    responseCode: "06",
  },
  "0311/0080": {
    id: "TBC",
    description: "Cashback transaction declined in offline mode",
    resultCode: "06",
    responseCode: "07",
  },
  "0311/0081": {
    id: "TBC",
    description: "Saveback transaction declined in offline mode",
    resultCode: "06",
    responseCode: "07",
  },
  "0311/2008": {
    id: "TBC",
    description: "Max amount exceeded / cashback not allowed",
    resultCode: "06",
    responseCode: "08",
  },
  "0311/0024": {
    id: "TBC",
    description: "Currency not supported",
    resultCode: "07",
    responseCode: "52",
  },
  "0311/0016": {
    id: "TBC",
    description: "Unknown TPV number (Internal AXIS Number)",
    resultCode: "07",
    responseCode: "40",
  },
  "0311/2010": {
    id: "TBC",
    description: "Card already inserted",
    resultCode: "07",
    responseCode: "40",
  },
  "0311/1005": {
    id: "TBC",
    description: "Requested referral",
    resultCode: "02",
    responseCode: "10",
  },
  "0311/1008": {
    id: "TBC",
    description: "Invalid request (for example: saveback not allowed)",
    resultCode: "07",
    responseCode: "40",
  },
  "0311/3001": {
    id: "MSG99624",
    description: "The card does not support this type of transaction.",
    resultCode: "06",
    responseCode: "07",
  },
  "0311/3002": {
    id: "MSG99624",
    description: "The card does not support this type of transaction",
    resultCode: "06",
    responseCode: "07",
  },
  "0311/1000": {
    id: "TBC",
    description: "The Key 'Offline Token' is abset (or getting an encryption error)",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0261": {
    id: "TBC",
    description: "Product(s) denied",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0262": {
    id: "TBC",
    description: "Too many products",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0263": {
    id: "TBC",
    description: "Tender value not equal to sum of product code(s)",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0264": {
    id: "TBC",
    description: "PKE refused for fuel cards",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0265": {
    id: "TBC",
    description: "All product codes are denied",
    resultCode: "07",
    responseCode: "40",
  },
  "0113/0266": {
    id: "TBC",
    description: "Partial tender refused for fuel card",
    resultCode: "07",
    responseCode: "40",
  },
} as PedResponseCode;
