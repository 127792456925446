import { DeviceServerEvents, Response } from "../../types";
import {
  AcquirerToInternalBankingMessages,
  AcquirerToInternalPaymentsMessages,
} from "./mappings/acquirierMessageMappings";
import { AcquirerToInternalResponseCodes } from "./mappings/acquirierResponseCodes";
import eventTagMappings from "./mappings/eventTagMappings";
import pedResponseCodes from "./mappings/pedResponseCodes";
import { ActionToTransactionType } from "./mappings/transactionTypes";
import { MessagePrompt, Metadata, PedActions, PedResult, TransactionTypes } from "./types";

export const FALLBACK_RESULT_CODE = "07";
export const FALLBACK_RESPONSE_CODE = "24";

export const PaymentActions = [PedActions.DebitX, PedActions.DebitY];

export const SUCCESSFUL_TRANSACTION_PROMPT = {
  id: "MSG40077",
  description: "The transaction has completed successfully.",
};

/**
 * Check if transaction was approved
 * @param response PedResult
 * @returns boolean
 */
export const isApproved = (response: PedResult): boolean => {
  return response.approved === true;
};

/**
 * Check if device service returned a critical error
 * @param response PedResult
 * @returns boolean
 */
export const isError = (response: Response): boolean => {
  return response.event === DeviceServerEvents.REQUEST_ERROR;
};

/**
 * Extract Metadata from response
 * @param action PedActions
 * @param response PedResult
 * @returns
 */

export const extractMetadata = (action: PedActions, response: PedResult): Metadata => {
  const metadata: Metadata = {};

  metadata.transactionType = ActionToTransactionType[action];

  if (response.acquirerResponseCode && AcquirerToInternalResponseCodes[response.acquirerResponseCode]) {
    metadata.responseCode = AcquirerToInternalResponseCodes[response.acquirerResponseCode].responseCode;
    metadata.resultCode = AcquirerToInternalResponseCodes[response.acquirerResponseCode].resultCode;
    return metadata;
  }

  if (pedResponseCodes[response.combinedCode]) {
    metadata.responseCode = pedResponseCodes[response.combinedCode].responseCode;
    metadata.resultCode = pedResponseCodes[response.combinedCode].resultCode;
    return metadata;
  }

  metadata.responseCode = FALLBACK_RESPONSE_CODE;
  metadata.resultCode = FALLBACK_RESULT_CODE;

  return metadata;
};

/**
 * Extract messaging prompt from response
 * @param type TransactionTypes
 * @param response PedResult
 * @returns
 */
export const extractMessaging = (type: TransactionTypes, response: PedResult): MessagePrompt | undefined => {
  const messages =
    type === TransactionTypes.Banking ? AcquirerToInternalBankingMessages : AcquirerToInternalPaymentsMessages;
  if (response.acquirerResponseCode && messages[response.acquirerResponseCode]) {
    return messages[response.acquirerResponseCode];
  }
};

/**
 * Set fallback messaging when we don't have a mapping for the acquirer response code or ped response code
 * @param transactionType TransactionTypes
 * @returns MessagePrompt | undefined
 */
export const setFallbackMessaging = (transactionType: TransactionTypes): MessagePrompt | undefined => {
  switch (transactionType) {
    case TransactionTypes.Banking:
      return {
        id: "MSG99619",
        description: "The transaction has been declined.",
      };
    case TransactionTypes.Payments:
      return {
        id: "MSG40078",
        description:
          "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
      };
  }
};

/**
 * Get Event Tag Mapping
 * @param message string
 * @returns MessagePrompt | undefine
 */
export const getEventTagMapping = (message: string): MessagePrompt | undefined => {
  for (const key in eventTagMappings) {
    if (message.startsWith(key)) {
      return eventTagMappings[key];
    }
  }
};
