import WebSocketAsPromised from "websocket-as-promised";
import { toBlob } from "html-to-image";
import { SupportedServices, Response, DeviceServerEvents } from "../../types";

import {
  ReceiptPrinterResult,
  ReceiptPrinterClient,
  ReceiptPrinterClientProps,
  ReceiptPrinterActions,
  ReceiptPrinterModes,
} from "./types";

const isError = (response: Response): boolean => {
  return response.event === DeviceServerEvents.REQUEST_ERROR;
};

const DEFAULT_WIDTH = 300;

export const buildReceiptPrinterClient = (requestHandler: WebSocketAsPromised["sendRequest"]): ReceiptPrinterClient => {
  const performAction = async (
    action: ReceiptPrinterActions,
    params: ReceiptPrinterClientProps
  ): Promise<ReceiptPrinterResult> => {
    const response = (await requestHandler({
      service: SupportedServices.ReceiptPrinter,
      action,
      params,
    })) as Response & ReceiptPrinterResult;

    if (isError(response)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  const print = async (receipt: string, mode?: ReceiptPrinterModes, width?: number): Promise<ReceiptPrinterResult> => {
    let receiptToBePrinted = receipt;

    return new Promise((resolve) => {
      if (!mode || mode === ReceiptPrinterModes.Html) {
        const htmlNode = document.createElement("div");
        htmlNode.innerHTML = receipt;

        toBlob(htmlNode, { width: width ?? DEFAULT_WIDTH }).then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob as Blob);
          reader.onloadend = () => {
            receiptToBePrinted = (reader.result as string).split("base64,")[1];
            resolve(
              performAction(ReceiptPrinterActions.Print, {
                receipt: receiptToBePrinted,
              })
            );
          };
        });
      } else {
        resolve(
          performAction(ReceiptPrinterActions.Print, {
            receipt: receiptToBePrinted,
            mode: ReceiptPrinterModes.Text,
          })
        );
      }
    });
  };

  return Object.freeze({
    print,
  });
};
