import { MessageMapping } from "../types";
import { AcquirerResponseCodes } from "./acquirierResponseCodes";

// Some of these codes are duplicated between banking & payments but in some cases
// specific messaging is displayed to the clerk depending on the type of transaction

export const GenericTransactionDeclinedPrompt = {
  id: "MSG99619",
  description: "The transaction has been declined.",
};

// PAYMENTS

export const AcquirerToInternalPaymentsMessages: MessageMapping = {
  [AcquirerResponseCodes.InternalIngenicoError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FormatError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidDate]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FINotAvailableForRouting]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SystemMalfunction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVCFailedDoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.CVV2FailedDoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.ReferToCardIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReferToCardIssuerSpecialConditions]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.PickUpSpecialConditions]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.SuspectedFraudPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.RestrictedCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.LostCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.StolenCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.HardCapturePickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CVCFailedCaptureCard]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.InvalidAmount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InvalidCardNumber]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCreditAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoUniversalAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoInvestmentAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCheckingAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoSavingsAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.IssuerOrSwitchInoperative]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded]: {
    id: "MSG00969",
    description:
      "The PIN is blocked.\n\nAdvise the customer NOT to remove the card until prompted to do so.\n\nThe customer may wish to contact the card issuer.",
  },
  [AcquirerResponseCodes.InvalidTransaction]: {
    id: "MSG99606",
    description:
      "The card does not support this type of transaction.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InsufficientFunds]: {
    id: "MSG99619",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.ExpiredCard]: {
    id: "MSG00810",
    description: "The supplied card has expired.",
  },
  [AcquirerResponseCodes.ExpiredCardPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.TransactionNotPermittedToCardholder]: {
    id: "MSG99606",
    description:
      "The card does not support this type of transaction.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.FINotAvailableForRouting]: {
    id: "MSG99603",
    description: "The transaction has failed.",
  },
  [AcquirerResponseCodes.DoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.CustomerCancellation]: {
    id: "MSG99604",
    description: "The transaction has been cancelled.",
  },
  [AcquirerResponseCodes.InvalidAmount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InvalidCardNumber]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCreditAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoUniversalAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCheckingAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoSavingsAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoInvestmentAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InvalidMerchant]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.Error]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.HonourWithIdentification]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestInProgress]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedForPartialAmount]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedVIP]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedUpdateTrack3]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerCancellation]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerDispute]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReEnterTransaction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidTerminalId]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedMalfunction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptableFee]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSupportedByReceiver]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnableToLocateRecordOnFile]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateFileUpdateRecordOldRecordReplaced]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateFieldEditError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateRecordLockedOut]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSuccesfulContactAcquirer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CompletedPartially]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestedFunctionNotSupported]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.OriginalAmountIncorrect]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ResponseReceivedTooLate]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.LawViolation]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateTransmission]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReconcileError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePaySystemUnavailable]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionRejected]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayAccountLimitReached]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.MNONotSupportedByIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptablePin]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMobilePhoneNumber]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinChangeFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinUnlockFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoDataSuppliedByIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FallbackNotAllowed]: GenericTransactionDeclinedPrompt,
};

// BANKING

export const AcquirerToInternalBankingMessages: MessageMapping = {
  [AcquirerResponseCodes.InternalIngenicoError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FormatError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidDate]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FINotAvailableForRouting]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SystemMalfunction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVCFailedDoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.CVV2FailedDoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.ReferToCardIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReferToCardIssuerSpecialConditions]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.PickUpSpecialConditions]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.SuspectedFraudPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.RestrictedCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.LostCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.StolenCardPickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.HardCapturePickUp]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CVCFailedCaptureCard]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.InvalidAmount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InvalidCardNumber]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCreditAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoUniversalAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoInvestmentAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCheckingAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoSavingsAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.PinChangeFailed]: {
    id: "MSG00987",
    description:
      "Advise the customer to remove their card.\n\nThe customer has failed to confirm the new PIN and has exceeded the maximum retries.",
  },
  [AcquirerResponseCodes.InvalidTransaction]: {
    id: "MSG99624",
    description: "The card does not support this type of transaction.",
  },
  [AcquirerResponseCodes.IncorrectPin]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.TransactionNotPermittedToCardholder]: {
    id: "MSG99624",
    description: "The card does not support this type of transaction.",
  },
  [AcquirerResponseCodes.InsufficientFunds]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalAmountLimit]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalFrequencyLimit]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExpiredCard]: {
    id: "MSG00810",
    description: "The supplied card has expired.",
  },
  [AcquirerResponseCodes.ExpiredCardPickup]: {
    id: "MSG00628",
    description:
      "The transaction has been declined.\n\nFollow the appropriate procedure to retain the card.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.AllowablePinTriesExceededPickup]: {
    id: "MSG00969",
    description:
      "The PIN is blocked.\n\nFollow the appropriate procedure to retain the card.\n\nThe customer may wish to contact the card issuer.",
  },
  [AcquirerResponseCodes.FINotAvailableForRouting]: {
    id: "MSG99603",
    description: "The transaction has failed.",
  },
  [AcquirerResponseCodes.DoNotHonour]: {
    id: "MSG40078",
    description: "The transaction has been declined.",
  },
  [AcquirerResponseCodes.CustomerCancellation]: {
    id: "MSG99604",
    description: "The transaction has been cancelled.",
  },
  [AcquirerResponseCodes.InvalidAmount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.InvalidCardNumber]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCreditAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoUniversalAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoCheckingAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoSavingsAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.NoInvestmentAccount]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\n Advise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.IssuerOrSwitchInoperative]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMerchant]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.Error]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.HonourWithIdentification]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestInProgress]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedForPartialAmount]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedVIP]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedUpdateTrack3]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerCancellation]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerDispute]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReEnterTransaction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidTerminalId]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedMalfunction]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptableFee]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSupportedByReceiver]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnableToLocateRecordOnFile]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateFileUpdateRecordOldRecordReplaced]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateFieldEditError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateRecordLockedOut]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSuccesfulContactAcquirer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CompletedPartially]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestedFunctionNotSupported]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.OriginalAmountIncorrect]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ResponseReceivedTooLate]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.LawViolation]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateTransmission]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReconcileError]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePaySystemUnavailable]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionRejected]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayAccountLimitReached]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.MNONotSupportedByIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptablePin]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMobilePhoneNumber]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinChangeFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinUnlockFailed]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoDataSuppliedByIssuer]: GenericTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FallbackNotAllowed]: GenericTransactionDeclinedPrompt,
};
