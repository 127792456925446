export interface ReceiptPrinterResult {
  printed: boolean;
}

export interface ReceiptPrinterClient {
  print(receipt: string, mode?: string, width?: number): Promise<ReceiptPrinterResult>;
}

export interface ReceiptPrinterClientProps {
  receipt: string;
  mode?: string;
}

export enum ReceiptPrinterActions {
  Print = "PRINT",
}

export enum ReceiptPrinterModes {
  Text = "text",
  Html = "html",
}
